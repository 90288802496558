@charset "UTF-8";
/* ====================================================
Font
==================================================== */
/* ====================================================
Position & transform
==================================================== */
/* ====================================================
Color
==================================================== */
/* ====================================================
Sizing
==================================================== */
/* ====================================================
Misc
==================================================== */
/*2501追加 フローティングバナー*/
.bnr-news {
  width: 373px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #fff;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 3;
  -webkit-box-shadow: 0 0 40px -5px rgba(26, 60, 77, 0.2);
  box-shadow: 0 0 40px -5px rgba(26, 60, 77, 0.2);
  opacity: 0;
  -webkit-transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
@media only screen and (max-width: 767px) {
  .bnr-news {
    width: 100%;
    max-width: 440px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.bnr-news.is_visible {
  opacity: 1;
}
.bnr-news .txt-wrap {
  width: 100%;
  padding: 20px 0 15px 20px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .bnr-news .txt-wrap {
    padding: 0 6.666vw;
  }
}
.bnr-news .txt-wrap .news-tit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
}
.bnr-news .txt-wrap .news-tit .tit {
  margin-top: -2px;
  margin-left: 10px;
  line-height: 1.1;
  font-size: 2rem;
  color: #808080;
}
@media only screen and (max-width: 767px) {
  .bnr-news .txt-wrap .news-tit .tit {
    font-size: 1.6rem;
  }
}
.bnr-news .txt-wrap .sub {
  margin-top: 0.8em;
  font-size: 1.5rem;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
}
@media only screen and (max-width: 767px) {
  .bnr-news .txt-wrap .sub {
    margin-top: 0.6em;
    font-size: 1.3rem;
  }
}
.bnr-news .txt-wrap .sub .note {
  margin-top: 0.3em;
  display: block;
  font-size: 0.7rem;
}
.bnr-news .image {
  width: 116px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  aspect-ratio: 93/100;
}
@media only screen and (max-width: 767px) {
  .bnr-news .image {
    width: auto;
    aspect-ratio: 38/27;
  }
}
@media only screen and (max-width: 767px) {
  .bnr-news .image img {
    width: auto;
    height: min(22.4vw, 100px);
    aspect-ratio: 38/27;
    object-fit: cover;
  }
}
.bnr-news .bnr-close {
  padding: 8px;
  line-height: 0;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.bnr-news .bnr-close img {
  width: 22px;
  height: 22px;
}

.ss-wedding .ss-wedding-inner .wedding-l {
  z-index: 1;
}

.ss-wedding .ss-wedding-inner .wedding-r {
  z-index: 2;
}