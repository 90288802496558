/*2501追加 フローティングバナー*/
.bnr-news {
    width: 373px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: #fff;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 3;
    -webkit-box-shadow: 0 0 40px -5px rgba(26, 60, 77, .2);
    box-shadow: 0 0 40px -5px rgba(26, 60, 77, .2);
    opacity: 0;
    -webkit-transition: all .4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: all .4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    
    
    @include sp {
        width: 100%;
        max-width: 440px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    
    &.is_visible {
        opacity: 1;
    }
    
    .txt-wrap {
        width: 100%;
        padding: 20px 0 15px 20px;
        position: relative;
        @include sp {
            padding: 0 6.666vw;
        }
        
        .news-tit {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            line-height: 1;
            
            .category {
            }
            
            .tit {
                margin-top: -2px;
                margin-left: 10px;
                line-height: 1.1;
                font-size: 2rem;
                color: #808080;
                @include sp {
                    font-size: 1.6rem;
                }
            }
        }
        
        .sub {
            margin-top: 0.8em;
            font-size: 1.5rem;
            -webkit-font-feature-settings: "palt";
            font-feature-settings: "palt";
            
            @include sp {
                margin-top: 0.6em;
                font-size: 1.3rem;
            }
            
            .note {
                margin-top: 0.3em;
                display: block;
                font-size: .7rem;
            }
        }
    }
    
    .image {
        width: 116px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        aspect-ratio: 93 / 100;
        @include sp {
            width: auto;
            aspect-ratio: 38 / 27;
        }
        img {
            @include sp {
                width: auto;
                height: min(22.4vw, 100px);
                aspect-ratio: 38 / 27;
                object-fit: cover;
            }
        }
    }
    
    .bnr-close {
        padding: 8px;
        line-height: 0;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        
        img {
            width: 22px;
            height: 22px;
        }
        
    }
    
}

//z-indexの調整
.ss-wedding .ss-wedding-inner .wedding-l {
    z-index: 1;
}
.ss-wedding .ss-wedding-inner .wedding-r {
    z-index: 2;
}